@import '../../scss/main.scss';

.project-page3{
	width: auto;
	height: auto;
	padding: 0rem 14rem 0rem 14rem;
	color: $dark-grey;
	margin-bottom: 6rem;
}

.pwire{
	margin-right: 1.25rem;
}

@media only screen and (max-width: 992px) {
	.project-page3{
		width: 100%;
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.pro-cover{
		width: 80%;
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.pro-title{
		width: 100%;
		text-align: center;
	}

	.pro-name{
		width: 100%;
		text-align: center;
	}

	.pro-content{
		width: 100%;
		text-align: center;
		margin-bottom: 0;
	}

	.pro-txt{
		width: 100%;
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
	}

	.pro-txt-content{
		text-align: center;
	}

	.pro-overview{
		width: 75%;
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: 2.0rem;
	}

	.pro-txt-content1{
		margin-bottom: 2.50rem;
	}

	.pro-image{
		width: 100%;
		height: auto;
	}

	.uf-image{
		width: 100%;
		height: auto;
	}

	.pro-img{
		margin: 3.5rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.pro-challenge{
		width: 75%;
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: 4.0rem;
	}

	.ptxt{
		font-size: 1.10rem;
		text-align: left;
	}

	.ptxt3{
		font-size: 1.10rem;
		text-align: left;
	}

	.ptxt4{
		font-size: 1.15rem;
		text-align: center;
	}

	.pro-userflow{
		width: 75%;
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.pro-wireframes{
		width: 75%;
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.uf-image{
		width: 100%;
		height: auto;
	}

	.pro-final{
		width: 75%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: 8rem;
		margin-bottom: 4rem;
	}

	.pwire{
		width: 50%;
		height: auto;
		margin-bottom: 2.50rem;
		margin-left: 2.20rem;
	}

	.pro-solution{
		width: 75%;
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-bottom: 4.0rem;
	}

	.pro-future{
		width: 75%;
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: 8rem;
	}

	.pro-linking{
		margin-bottom: 8.5rem;
	}

}
