@import '../../scss/main.scss';

.navBar{
	width: auto;
	height: 10vh;
	display: flex;
	flex-direction: row;
	padding: 0rem 4rem 0rem 14.2rem;
	margin-bottom: 2rem;
}

.logo-box{
	width: 50%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.logo{
	text-decoration: none;
	color: $blue;
	font-family: $body-stack;
	font-weight: bold;
	font-size: 1.2rem;
	transition: all 350ms;
}

.logo:hover{
	color: $grey;
}

.links-box{
	width: 50%;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}

.link1, .link2, .link3{
	text-decoration: none;
	color: $dark-grey;
	font-size: 1.2rem;
	transition: all 350ms;
}

.link1:hover, .link2:hover, .link3:hover{
	color: $grey;
}

@media only screen and (max-width: 992px) {
	.navBar{
		width: 100%;
		height: 30vh;
		margin:0;
		padding:0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.logo-box{
		justify-content: center;
		align-items: center;
		margin-top: 2rem;
		margin-bottom: 1.50rem;
		margin-left: 0.10rem;
	}


	.links-box{
		justify-content: center;
		align-items: center;
		margin-left: 0.25rem;
		margin-right: 0.25rem;
	}

	.logo{
		font-size: 1.50rem;
	}

	.link1, .link2, .link3{
		margin-left: 1rem;
		padding: 0.5rem;
		font-size: 1.50rem;
	}

}
