@import '../../scss/main.scss';

.aboutEducation{
	margin-top: 4rem;
	margin-bottom: 8rem;
	padding: 0rem 14rem 0rem 14rem;
	width: auto;
	height: auto;
	color: $dark-grey;
}

.edu1{
	margin-bottom: 4rem;
}

.edu{
	margin-bottom: 8rem;
}

.e-content{
	width: auto;
	text-align: justify;
}

@media only screen and (max-width: 992px) {
	.aboutEducation{
		width: 85%;
		height: auto;
		margin: 0;
		padding-left: 1.5rem;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.e-content{
		text-align: justify;
		font-size: 1.10rem;
	}

	.e-title-2{
		width: 80%;
		font-size: 1.25rem;
		text-align: left;
		margin-bottom: 1.0rem;
	}

	.e-title-3{
		width: 85%;
		font-size: 1.10rem;
	}

	.edu{
		margin-bottom: 2rem;
	}

}
