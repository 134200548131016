@import '../../scss/main.scss';

.aboutExperience{
	margin-top: 4rem;
	margin-bottom: 8rem;
	padding: 0rem 14rem 0rem 14rem;
	width: auto;
	height: auto;
	color: $dark-grey;
}

.exps1{
	margin-bottom: 4rem;
}

.exps{
	margin-bottom: 4rem;
}

.e-title-2{
	font-family: $body-stack;
	font-size: 1.3rem;
	font-weight: bold;
	margin: 0;
}

.e-title-3{
	font-family: $body-stack;
	font-size: 1.1rem;
	color: $grey;
	margin: 0;
}

@media only screen and (max-width: 992px) {
	.aboutExperience{
		width: 85%;
		height: auto;
		margin: 0;
		padding-left: 1.5rem;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-top: 2.5rem;
		margin-bottom: 2.5rem;
	}

	.e-content{
		text-align: left;
	}

}
