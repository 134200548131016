@import '../../scss/main.scss';

.hCover{
	width: auto;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.homeCover{
	width: auto;
	height: auto;
	padding: 0rem 14rem 0rem 14rem;
	color: $dark-grey;
	margin-bottom: 6rem;
}

.c-title{
	font-size: 1.5rem;
	margin-bottom: 3.5rem;
	color: $dark-grey;
}

.c-title-main{
	font-size: 3.5rem;
	font-family: $body-stack;
	color: $blue;
	margin-bottom: 1.5rem;
}

.c-intro{
	height: auto;
	width: 40%;
	font-size: 1.2rem;
	line-height: 2rem;
	text-align: justify;
	margin-bottom: 2.0rem;
	color: $dark-grey;
}

.c-text{
	font-size: 1.2rem;
	color: $dark-grey;
}

.c-link{
	font-size: 1.2rem;
	color: $blue;
	transition: all 350ms;
	text-decoration: none;
}

.c-link:hover{
	color: $grey;
}

.blob {
	position: absolute;
	fill: $blue;
	width: 20vmax;
	animation: move 10s ease-in-out infinite;
	margin-left: 60rem;
	filter: blur(7px);
	-webkit-filter: blur(7px);
	transition: all 350ms;
}

@keyframes move {
	0%   { transform: scale(1)  translate(10px, -30px); }
	38%  { transform: scale(0.8, 1)  rotate(160deg); }
	40%  { transform: scale(0.8, 1)  rotate(160deg); }
	78%  { transform: scale(1.1) rotate(-20deg); }
	80%  { transform: scale(1.1)  rotate(-20deg); }
	100% { transform: scale(1)   translate(10px, -30px); }
}

.blob:hover{
	fill: $grey;
}


@media only screen and (max-width: 992px) {
	.homeCover{
		width: 100%;
		height: auto;
		margin: 0;
		padding-left: 1.5rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		margin-top: 4rem;
	}

	.hCover{
		width: 100%;
		height: auto;
		margin: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
	}

	.blob{
		display: none;
		visibility: hidden;
	}

	.c-title-main{
		font-size: 2.5rem;
	}

	.c-intro{
		width: 78%;
		letter-spacing: 1px;
	}

	.c-text, .c-link{
		width: 70%;
	}

}
